<template>
  <div class="view-signManage">
    <ul class="list">
      <li
        class="item"
        :class="{ active: item.defaultSignature }"
        v-for="(item, index) in userSignList"
        :key="index"
        @click="setDefaultUserSign(item)"
      >
        <img :src="item.signatureKey" alt="" class="sign" />
        <img
          src="@/assets/imgs/signManage/close.png"
          alt=""
          class="close"
          @click.stop="delUserSign(item)"
          v-if="!item.defaultSignature"
        />
        <img
          src="@/assets/imgs/signManage/tagActive.png"
          alt=""
          class="tag"
          v-if="item.defaultSignature"
        />
        <img src="@/assets/imgs/signManage/tag.png" alt="" class="tag" v-else />
      </li>
      <li class="addSign" v-if="userSignList.length < 4" @click="addSign">
        <img src="@/assets/imgs/signManage/add.png" alt="" />
        <div class="tag">添加签名</div>
      </li>
    </ul>
  </div>
</template>

<script>
  import myApi from '@/api/my'
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        userSignList: [],
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    created() {
      this.getMyInfo()
    },
    methods: {
      delUserSign(item) {
        myApi
          .delUserSign({
            signId: item.signIdE,
          })
          .then(res => {
            this.getMyInfo()
          })
      },
      addSign() {
        // 签名数量等于4，提示用户不能添加
        const signCount = this.myInfo?.userSignList?.length
        if (signCount >= 4) {
          this.$toast('签名数量不能超过4个')
          return
        }
        const { creditStatus, userId } = this.userInfo
        this.$router.push({
          path: '/addSignName',
          query: {
            creditStatus,
            userId,
            subId: 1,
          },
        })
      },
      setDefaultUserSign(item) {
        myApi
          .setDefaultUserSign({
            signId: item.signIdE,
          })
          .then(res => {
            this.getMyInfo()
          })
      },
      getMyInfo() {
        myApi.getMyInfo({}).then(res => {
          this.userSignList = res.userSignList
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .view-signManage {
    padding: 16px;
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: 163px;
        height: 79px;
        margin-bottom: 19px;
      }
      .item {
        position: relative;
        background: #ffffff;
        border: 1px solid #dcdee0;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
        .tag {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 18px;
        }
        .close {
          width: 14px;
          position: absolute;
          right: -6px;
          top: -6px;
        }
      }
      .active {
        border: 1px solid rgba(22, 118, 255, 0.4);
      }
      .addSign {
        border: 1px dashed #dcdee0;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #969799;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        padding-top: 18px;
        img {
          width: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }
</style>
